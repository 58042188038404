import { bindable, inject } from 'aurelia-framework';
import { I18N }             from 'aurelia-i18n';
import { BaseViewModel }    from 'base-view-model';
import { AppContainer }     from 'resources/services/app-container';

@inject(AppContainer, I18N)
export class PropertiesListing extends BaseViewModel {
    @bindable propertiesRecords;
    @bindable showDatatable = false;
    @bindable disabled      = false;
    @bindable datatable;

    propertySubscriptions = [];
    listingId             = 'formulation-studies-properties-listing';

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);

        this.bindingEngine         = appContainer.bindingEngine;
        this.propertySubscriptions = [];

        this.self = this;
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.subscribeObservers();
    }

    /**
     * Handles activate event
     *
     * @param params
     * @return {Promise<void>}
     */
    async activate(params) {
        super.activate();

        this.propertiesRecords = params.properties;
        this.completeModel     = params.completeModel;
        this.readonly          = params.readonly;

        this.observeArray(this.propertiesRecords);

        await this.initializeProperties().then(() => {
            this.defineDatatable();
            this.handleShowDatatable();
        });
    }

    /**
     * Shows or hides the datatable
     *
     * @param show
     * @return {any}
     */
    handleShowDatatable(show = true) {
        return this.showDatatable = show && this.propertiesRecords.length > 0;
    }

    /**
     * Handles properties records changed event
     */
    async propertiesRecordsChanged(newRecords) {
        this.handleShowDatatable(false);
        this.observeArray(newRecords);
        await this.initializeProperties()
        this.handleShowDatatable(true);
    }

    /**
     * Subscribes observers
     *
     * @param array
     */
    observeArray(array) {
        this.propertySubscriptions.forEach(sub => sub.dispose());
        this.propertySubscriptions = [];

        if (Array.isArray(array)) {
            this.observeProperties(array);
        }
    }

    /**
     * Observes properties
     *
     * @param array
     */
    observeProperties(array) {
        this.propertySubscriptions.forEach(sub => sub.dispose());
        this.propertySubscriptions = [];

        array.forEach(item => {
            this.propertySubscriptions.push(
                this.bindingEngine.propertyObserver(item, 'value').subscribe(() => this.handlePropertyChange(item)),
            );
            this.propertySubscriptions.push(
                this.bindingEngine.propertyObserver(item, 'active').subscribe(() => this.handlePropertyChange(item)),
            );
        });
    }

    /**
     * Handles property change
     *
     * @param changedItem
     */
    handlePropertyChange(changedItem) {
        this.completeModel.properties = this.propertiesRecords;
    }

    /**
     * Handles detached event
     */
    detached() {
        this.propertySubscriptions.forEach(sub => sub.dispose());
    }

    /**
     * Initializes properties
     */
    initializeProperties() {
        return Promise.resolve(() => {
            if (Array.isArray(this.propertiesRecords)) {
                let newModel = [];

                for (let property of this.propertiesRecords) {
                    let modelProperty = this.completeModel ? this.completeModel.properties.find(
                        p => p.property_id === property.property_id,
                    ) : null;

                    if (!modelProperty) {
                        modelProperty = {
                            property_id: property.property_id,
                            active:      property.active !== undefined ? property.active : true,
                            value:       property.value !== undefined ? property.value : '',
                        };
                    } else {
                        if (modelProperty.active === undefined) {
                            modelProperty.active = true;
                        }
                        if (modelProperty.value === undefined) {
                            modelProperty.value = '';
                        }
                    }

                    newModel.push(modelProperty);
                }

                this.completeModel.properties = newModel;
            }
        });
    }

    /**
     * Defines the datatable
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search: this.search.bind(this),
            },
            showFilter:          false,
            showOptionsDropdown: false,
            destroySelected:     false,
            selectable:          false,
            showResultsPerPage:  false,
            buttons:             [],
            sorting:             {
                column:    1,
                direction: 'asc',
            },
            columns:             [
                {
                    name:       'active',
                    data:       'active',
                    title:      'form.field.applicable',
                    type:       'checkbox',
                    orderable:  false,
                    searchable: false,
                    center:     true,
                },
                {
                    data:       'name',
                    name:       'name',
                    title:      'form.field.name',
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:       'value',
                    name:       'value',
                    title:      'form.field.value',
                    type:       'input',
                    orderable:  false,
                    searchable: false,
                    center:     true,
                },
                {
                    name:       'unit',
                    data:       'unit',
                    title:      'form.field.unit',
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @returns {*}
     */
    search(criteria) {
        let records = this.propertiesRecords;

        if (criteria.search.value !== null && criteria.search.value !== '') {
            let regExp = new RegExp(criteria.search.value, 'gi');

            records = this.records.filter((record) => {

                return ('' + record.name).match(regExp)
                    || ('' + record.value).match(regExp)
                    || ('' + record.unit).match(regExp);
            });
        }

        return Promise.resolve({
            data:            records,
            recordsTotal:    this.propertiesRecords.length,
            recordsFiltered: records.length,
        });
    }

}
